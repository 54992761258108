import React, { useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { Button } from '@material-ui/core';
import { LinearProgress, Container } from '@material-ui/core';
import axios from 'axios';
import AboutDialog from '../about/AboutDialog';

function CapeConnections() {
  const [videoURL, setVideoURL] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoDescription, setVideoDescription] = useState('');

  var config = {
    method: 'GET',
    url: `https://api.vimeo.com/users/lctv/albums/7046008/videos?per_page=1&page=1&sizes=1920x1080`,
    headers: {
      Authorization: 'Bearer 84e608f5390d34d9b60ab8b0325fb170',
    },
  };

  axios(config)
    .then(function (response) {
      setVideoURL(response.data.data[0].player_embed_url);
      setVideoTitle(response.data.data[0].name);
      setVideoDescription(response.data.data[0].description);
    })
    .catch(function (error) {
      console.log(error);
    });

  return (
    <div>
      <div className="header">
        <Button>
          <AboutDialog />
          {/* <Avatar src="logo192.png" /> */}
        </Button>
        Cape Connections
      </div>
      {videoURL === '' ? (
        <LinearProgress />
      ) : (
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={videoURL}
            config={{
              vimeo: {
                playerOptions: {
                  responsive: true,
                },
              },
            }}
            controls
            width="100%"
            height="100%"
          />
          <Container>
            <h4>{videoTitle}</h4>
            <p>{videoDescription}</p>
          </Container>
        </div>
      )}
    </div>
  );
}

export default CapeConnections;
