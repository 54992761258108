import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { Button } from '@material-ui/core';
import TownVideosDialog from './TownVideosDialog';
import AboutDialog from '../about/AboutDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 100,
  },
  imageList: {
    width: 500,
    height: 'auto',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.8)',
  },
}));

let townData = [
  {
    name: 'Brewster',
    collectionId: '8733273',
    img: './images/brewster.png',
  },
  {
    name: 'Chatham',
    collectionId: '8733275',
    img: './images/chatham.jpeg',
  },
  {
    name: 'Eastham',
    collectionId: '8733276',
    img: './images/eastham.png',
  },
  {
    name: 'Orleans',
    collectionId: '8733278',
    img: './images/orleans.png',
  },
  {
    name: 'P-town',
    collectionId: '8733280',
    img: './images/ptown.png',
  },
  {
    name: 'Truro',
    collectionId: '8733283',
    img: './images/truro.jpeg',
  },
  {
    name: 'Wellfleet',
    collectionId: '8733284',
    img: './images/wellfleet.jpeg',
  },
  {
    name: 'Regional',
    collectionId: '8733272',
    img: './images/regional.png',
  },
];

// const handleTownSelect = (town, name) => {
//   console.log(town, name);
//   return <TownVideosDialog name={name} collectionId={town} />;
// };

function TownList() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="header">
        <Button>
          <AboutDialog />
        </Button>
        Lower Cape Community Reporting
      </div>
      <ImageList rowHeight={180} className={classes.imageList}>
        <ImageListItem
          key="Subheader"
          cols={2}
          style={{ height: 'auto' }}
        ></ImageListItem>
        {townData.map((item) => (
          <ImageListItem key={item.img}>
            <img src={item.img} alt={item.name} />
            <ImageListItemBar
              title={item.name}
              actionIcon={
                <TownVideosDialog
                  name={item.name}
                  collectionId={item.collectionId}
                />
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

export default TownList;
