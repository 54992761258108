import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import TvIcon from '@material-ui/icons/Tv';
import VideoList from '../VideoList';
import WatchLive from '../live/WatchLive';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LeakAddIcon from '@material-ui/icons/LeakAdd';
import TownList from '../towns/TownList';
import CapeConnections from '../capeConnections/CapeConnections';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 2000,
    paddingBottom: 30,
    paddingTop: 10,
    borderTop: 1,
  },
  ava: {
    width: 30,
    height: 30,
  },
});

export default function BottomNav() {
  const classes = useStyles();
  const [value, setValue] = useState(<VideoList />);

  return (
    <div className="appHolder">
      <div className="contentHolder">
        <div className="content">{value}</div>
      </div>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          value={<VideoList />}
          label="Watch Stories"
          // icon={<VideoLibraryIcon />}
          icon={<VideoLibraryIcon />}
        />
        <BottomNavigationAction
          value={<WatchLive />}
          label="Watch LCTV Live"
          icon={<TvIcon />}
        />
        <BottomNavigationAction
          value={<TownList />}
          label="Lower Cape Towns"
          icon={<HomeWorkIcon />}
        />
        <BottomNavigationAction
          value={<CapeConnections />}
          label="Cape Connections"
          icon={<LeakAddIcon />}
        />
      </BottomNavigation>
    </div>
  );
}
