import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import PlayVimeoVideo from './PlayVimeoVideo';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#000000',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const { video } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span className="material-icons md-light" onClick={handleClickOpen}>
        play_circle
      </span>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar>
            <span
              className="material-icons"
              onClick={handleClose}
              aria-label="close"
              edge="start"
            >
              close{' '}
            </span>
            <span
              className={classes.title}
              onClick={handleClose}
              aria-label="close"
            >
              close
            </span>
          </Toolbar>
        </AppBar>

        <PlayVimeoVideo video={video} />
      </Dialog>
    </div>
  );
}
