import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PlayVimeoVideo from './components/videos/PlayVimeoVideo';
import BottomNav from './components/layout/BottomNavigation';
import WatchLive from './components/live/WatchLive';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/" component={BottomNav} />
          <Route path="/play" component={PlayVimeoVideo} />
          <Route path="/live" component={WatchLive} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
