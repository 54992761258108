import React, { useState } from 'react';
import useVimeo from './../useVimeo';
import PlayVideoDialog from './../videos/PlayVideoDialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box } from '@material-ui/core';

function TownVideos(props) {
  const { albumId } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const { videos, hasMore, error } = useVimeo(albumId, pageNumber);

  return (
    <div className="video-list-holder">
      <InfiniteScroll
        dataLength={videos.length}
        next={() => setPageNumber(pageNumber + 1)}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        {videos &&
          videos.map((video, index) => (
            <div key={video.uri} className="list-item-holder">
              <div className="img-gradient">
                <img src={video.pictures.sizes[0].link} alt="thumb of video" />
                <h4 className="title">
                  <PlayVideoDialog video={video} />
                  {video.name}
                </h4>
              </div>
            </div>
          ))}
      </InfiniteScroll>
      <Box my={5} />
      <div>{error && 'Error'}</div>
    </div>
  );
}

export default TownVideos;
