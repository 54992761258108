import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import TownVideos from './TownVideos';
import { DialogContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    color: '#000000',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #000000',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TownVideosDialog(props) {
  const { name, collectionId } = props;

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <div>
      <span className={'material-icons white-text'} onClick={handleClickOpen}>
        play_circle
      </span>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar>
            <span
              className="material-icons"
              onClick={handleClose}
              aria-label="close"
              edge="start"
            >
              arrow_back{' '}
            </span>
            <span
              className={classes.title}
              onClick={handleClose}
              aria-label="close"
            >
              back to towns
            </span>
            <h3>{name}</h3>
          </Toolbar>
        </AppBar>
        <DialogContent
          id="scrollableDiv"
          style={{ padding: 0, marginTop: -49 }}
        >
          <TownVideos albumId={collectionId} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
