import React from 'react';
import { Button } from '@material-ui/core';
import AboutDialog from '../about/AboutDialog';

function WatchLive() {
  return (
    <div>
      <div className="header">
        <Button>
          <AboutDialog />
        </Button>
        Watch Lower Cape TV Live
      </div>
      <div className="player-wrapper">
        <iframe
          className="react-player"
          src="https://cloud.castus.tv/vod/lowercapetv/embed/6321f283aac485000961ef27?page=HOME&type=live&embedID=6321f283aac485000961ef27"
          title="live"
          sandbox="allow-same-origin allow-forms allow-scripts"
          allowtransparency="true"
          scrolling="auto"
          id="6321f283aac485000961ef27"
          webkitallowfullscreen="true"
          allowFullScreen
          mozallowfullscreen="true"
          frameBorder="0"
          onload="AdjustIframeHeightOnLoad('6321f283aac485000961ef27')"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
}

export default WatchLive;
