import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import { LinearProgress, Container, Button } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { shareOnMobile } from 'react-mobile-share';

function PlayVimeoVideo(props) {
  const { video } = props;
  const id = video.uri.substring(8);

  return (
    <div>
      {video !== null ? (
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={`https://player.vimeo.com/video/${id}`}
            config={{
              vimeo: {
                playerOptions: {
                  responsive: true,
                },
              },
            }}
            controls
            width="100%"
            height="100%"
          />
          <Container className="video-info">
            <h4>{video.name}</h4>
            <p>{video.description}</p>

            <Button
              variant="contained"
              color="default"
              className="share-button"
              onClick={() =>
                shareOnMobile(
                  {
                    text: 'Check out this story from LCTV',
                    url: video.link,
                    title: video.name,
                    images: [],
                  },
                  (message) => alert(message)
                )
              }
            >
              <ShareIcon /> Share
            </Button>
          </Container>
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

export default PlayVimeoVideo;
