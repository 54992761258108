import React, { useState } from 'react';
import useVimeo from './useVimeo';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Button, LinearProgress } from '@material-ui/core';
import PlayVideoDialog from './videos/PlayVideoDialog';
import AboutDialog from './about/AboutDialog';
import WeatherBanner from './layout/WeatherBanner';

function VideoList() {
  const [albumId, setAlbumId] = useState(5017763);
  const [pageNumber, setPageNumber] = useState(1);

  const { videos, hasMore, error } = useVimeo(albumId, pageNumber);

  const handleAlbumSelect = (id) => {
    setPageNumber(1);
    setAlbumId(id);
  };

  return (
    <div className="video-list-holder">
      <div className="fixed-header">
        <WeatherBanner />
        <div className="albumSelector">
          <Button>
            <AboutDialog />
            {/* <Avatar src="logo192.png" /> */}
          </Button>
          <Button
            className={`${
              albumId === 5017763 ? 'currentSelection active' : 'notActive'
            }`}
            id="5017763"
            onClick={() => handleAlbumSelect(5017763)}
          >
            Current News
          </Button>
          <Button
            className={`${
              albumId === 6096692 ? 'currentSelection active' : 'notActive'
            }`}
            id="6096692"
            onClick={() => handleAlbumSelect(6096692)}
          >
            ArtsLight
          </Button>
          <Button
            className={`${
              albumId === 6525861 ? 'currentSelection active' : 'notActive'
            }`}
            onClick={() => handleAlbumSelect(6525861)}
          >
            Coast+Climate
          </Button>
          <Button
            className={`${
              albumId === 7642089 ? 'currentSelection active' : 'notActive'
            }`}
            onClick={() => handleAlbumSelect(7642089)}
          >
            CAPeCONOMY
          </Button>
          <Button
            className={`${
              albumId === 5694953 ? 'currentSelection active' : 'notActive'
            }`}
            onClick={() => handleAlbumSelect(5694953)}
          >
            Education
          </Button>
          <Button
            className={`${
              albumId === 5694950 ? 'currentSelection active' : 'notActive'
            }`}
            onClick={() => handleAlbumSelect(5694950)}
          >
            Lifestyle
          </Button>
          <Button
            className={`${
              albumId === 5694956 ? 'currentSelection active' : 'notActive'
            }`}
            onClick={() => handleAlbumSelect(5694956)}
          >
            Politics+Policy
          </Button>
          <Button
            className={`${
              albumId === 3665039 ? 'currentSelection active' : 'notActive'
            }`}
            onClick={() => handleAlbumSelect(3665039)}
          >
            PSAs
          </Button>
        </div>

        {videos.length < 6 ? <LinearProgress color="primary" /> : null}
      </div>

      <InfiniteScroll
        dataLength={videos.length}
        next={() => setPageNumber(pageNumber + 1)}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      >
        {videos &&
          videos.map((video, index) => (
            <div key={video.uri} className="list-item-holder">
              <div className="img-gradient">
                <img
                  src={video.pictures.sizes[0].link}
                  alt="thumb of video"
                  width={1920}
                  height={1080}
                />
                <h4 className="title">
                  <PlayVideoDialog video={video} />
                  {video.name}
                </h4>
              </div>
            </div>
          ))}
      </InfiniteScroll>
      <Box my={5} />
      <div>{error && 'Error'}</div>
    </div>
  );
}

export default VideoList;
