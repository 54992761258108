import React, { useState, useEffect } from 'react';

const WeatherBanner = () => {
  const [weather, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `https://data.api.xweather.com/conditions/pws_lctvweatherstation?format=json&plimit=1&filter=1min&fields=periods.tempF,periods.humidity,periods.windSpeedMPH,periods.windDir,periods.weather&client_id=${process.env.REACT_APP_WEATHER_ID}client_secret=${process.env.REACT_APP_WEATHER_SECRET}`
        );
        const data = await response.json();
        setData(data);
        console.log('Data: ', data.response[0].periods[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="weather-wrapper">
      <p className="weatherbanner-title">
        LCTV Live weather! At our Orleans studio:
      </p>
      <div className="weather-banner">
        <p>
          {weather && Math.round(weather.response[0].periods[0].tempF)}
          &deg;
        </p>
        <p className="weather-desc">
          {weather && weather.response[0].periods[0].weather}
        </p>
        <p className="wind">
          wind{' '}
          {weather && Math.round(weather.response[0].periods[0].windSpeedMPH)}{' '}
          mph {weather && weather.response[0].periods[0].windDir}
        </p>
        <p>
          humidity{' '}
          {weather && Math.round(weather.response[0].periods[0].humidity)}%
        </p>
      </div>
    </div>
  );
};

export default WeatherBanner;
