import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  DialogContent,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    color: '#000000',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #000000',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    cursor: 'pointer',
  },
  button: {
    padding: 15,
    border: 'none',
    color: '#FFFFFF',
    backgroundColor: '#A92F2B',
    borderRadius: 5,
    width: '100%',
  },
  buttonText: {
    color: '#FFFFFF',
    textDecoration: 'none',
    fontSize: '1.2em',
  },
  respImage: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
  },
  phoneLink: {
    textDecoration: 'none',
    color: '#A92F2B',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function AboutDialog() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <div>
      <Avatar src="logo192.png" onClick={handleClickOpen} />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar>
            <span
              className="material-icons"
              onClick={handleClose}
              aria-label="close"
              edge="start"
            >
              arrow_back{' '}
            </span>
            <span
              className={classes.title}
              onClick={handleClose}
              aria-label="close"
            >
              back
            </span>
            <h3>ABOUT LCTV</h3>
          </Toolbar>
        </AppBar>
        <DialogContent
          id="scrollableDiv"
          style={{
            padding: 15,
            marginTop: 0,
            overflow: 'scroll',
            paddingBottom: 100,
          }}
        >
          <Card elevation={5}>
            <CardContent>
              <p>
                <b>Lower Cape TV</b>
                <br></br>5 Namskaket Road<br></br> Orleans, MA 02653
                <br></br>Studio Phone:{' '}
                <a className={classes.phoneLink} href="tel:508.694.3500">
                  508.694.3500
                </a>
              </p>
            </CardContent>
          </Card>
          <Box mt={2} />
          <Card elevation={5}>
            <CardContent>
              <h2>Newsletters worth your attention.</h2>
              <p>Receive updates directly in your inbox.</p>
              <button className={classes.button}>
                <a
                  href="https://preview.mailerlite.io/preview/272749/sites/78403980507481530/1h4BI4"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.buttonText}
                >
                  Newsletter Signup
                </a>
              </button>
            </CardContent>
          </Card>
          <Box mt={2} />
          <Card elevation={5}>
            <CardContent>
              <img
                className={classes.respImage}
                src="images/cape-connector.png"
                alt="Cape Connector Logo"
              />
              <h2>
                Your support helps connect, sustain &amp; share what matters!
              </h2>
              <p>Local Matters!</p>
              <button className={classes.button}>
                <a
                  href="https://lowercapetv.networkforgood.com/projects/109358-capeconnector"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.buttonText}
                >
                  Donate Now
                </a>
              </button>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}
