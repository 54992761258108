import { useEffect, useState } from 'react';
import axios from 'axios';

export default function useVimeo(albumId, pageNumber) {
  const [error, setError] = useState(false);
  const [videos, setVideos] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setVideos([]);
  }, [albumId]);

  useEffect(() => {
    setError(false);
    var config = {
      method: 'GET',
      url: `https://api.vimeo.com/users/lctv/albums/${albumId}/videos?per_page=6&page=${pageNumber}&sizes=1920x1080`,
      headers: {
        Authorization: 'Bearer 84e608f5390d34d9b60ab8b0325fb170',
      },
    };
    axios(config)
      .then(function (response) {
        // setVideos(response.data.data)
        setVideos((prevVideos) => {
          return [...new Set([...prevVideos, ...response.data.data])];
        });
        setHasMore(response.data.paging.next !== null);
      })
      .catch(function (error) {
        console.log(error);
        setError(true);
      });
  }, [albumId, pageNumber]);

  return { error, videos, hasMore };
}
